import * as React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import scrollTo from "gatsby-plugin-smoothscroll"
import { Helmet } from "react-helmet"

import UnlockBale from "../components/UnlockBale"
import ReducedLandfill from "../components/ReducedLandfill"
import GatherData from "../components/GatherData"
import EfficientOperations from "../components/EfficientOperations"
import ValueProps from "../components/ValueProps"
import DataAnalysis from "../components/DataAnalysis"
import Layout from "../components/Layout"
import "../components/Layout.css"
import Seo from "../components/Seo"

// styles
const Feature = styled.div`
  a {
    display: inline-block;
  }

  h1 {
    color: rgba(255, 0, 94, 1);
    transition: 3s .2s cubic-bezier(0.01, 0.8, 0.2, 1);

    @media (max-width:640px) {
      line-height: 1.1
    }
  }
`

const FeatureBoxWrapper = styled.div`
  overflow: hidden;
`

const FeatureBox = styled.div`
  ${'' /* background: url(${background});
  background-position: center;
  background-size: cover;
  height: 50vh; 
  margin-top: 12px;
  border-radius: 18px;
  animation: BackgroundZoomOut 1s ease;
  transition: 3s .2s cubic-bezier(0.01, 0.8, 0.2, 1);
  overflow: hidden;*/}
  ${'' /* transition: transform 1s; */}

  :hover {
    ${'' /* transform: scale(1.05); */}
    animation: BackgroundZoomIn 1s ease;
    overflow: hidden;

  }
`
const BackArrow = styled.div`
  width: 57px;
  cursor: pointer;
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;

  :hover i, :hover p {
    color: rgba(60, 200, 215, 1);
  }  

  i {
    content: "\f007";
    font-family: 'Font Awesome 5 Free', 'Font Awesome 5 Brands';
    color: rgba(0, 0, 0, .78);
    font-style: normal;
  }

  p {
    font-family: 'Alpino-Bold' , 'Arial', 'Helvetica', 'sans-serif';
    font-size: 19.2px;
    margin: 0;
  }

  @keyframes BackgroundZoomIn {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(1.2);
    }
  }

  @keyframes BackgroundZoomOut {
    0% {
      transform: scale(1.2);
    }
    100% {
      transform: scale(1);
    }
  }
`

const Introduction = styled.div`
  margin-top: 36px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 2.5vw;

  a {
    font-weight: 500;
    color: rgba(255, 0, 94, 1);
  }

  a:hover {
    color: rgba(60, 200, 215, 1);
  }

  @media (max-width:640px) {
    grid-template-columns: repeat(1, 1fr);
  }
`
const ProjectOverViewWrapper = styled.div`
  grid-column-start: 1;
  grid-column-end: 3;

  @media (max-width:640px) {
    grid-column-start: 1;
    grid-column-end: 1;
  }
`
const ProjectOverview = styled.div`
`
const ProjectDetailsWrapper = styled.div`
  grid-column-start: 3;
  grid-column-end: 3;

  @media (max-width:640px) {
    grid-column-start: 1;
    grid-column-end: 1;
  }
`
const ProjectDetails = styled.div`
  hr {
    opacity: 0;

    @media (max-width:640px) {
      opacity: 1;
      padding-top: 24px;
    }
  }

  @media (max-width:640px) {
    margin-top: 24px;
  }
`

const ProjectDescription = styled.div`
  padding-bottom: 24px;

  @media (max-width: 640px) {
    padding: 0;
  }
  
  p {
    margin-right: 15%;

    @media (max-width:640px) {
      margin-right: auto;
    }
  }

  img {

    @media (max-width:640px) {
      width: 100%;
    }
  }
`

const ScrollOnUp = styled.div`
    display: block;

    :hover i, :hover p {
        color: rgba(60, 200, 215, 1);
    }
`

const ProjectWrapper = styled.div`
  max-width: 1344px;
  margin: 0 auto;
`

const AnimationHut = styled.div`
  padding: 24px 24px 64px 24px;
  background-color: rgba(0, 0, 0, .78);
  border-radius: 18px;
  align-items: center;

  .imgcaption {
    color: white;
  }
`

const AnimationWrapper = styled.div `
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 2.5%;
  margin: 24px 0;
  align-items: center;

  @media (max-width: 640px) {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 18px;
  }
`

const ltt = () => (
    <Layout>
      <Seo title="Vector Animations" />
        <Helmet>
            <script src="https://kit.fontawesome.com/077761ae24.js" crossOrigin="anonymous"></script>
            <script src="https://unpkg.com/@lottiefiles/lottie-player@0.4.0/dist/lottie-player.js"></script>
        </Helmet>
        <div className="Hero">
        <div id="top"/>
        <ProjectWrapper>
            <Feature>
            <Link to="/work">
                <BackArrow>
                <i className="fas fa-long-arrow-alt-left"></i>
                <p>back</p>
                </BackArrow>
            </Link>
            <h1>Fun with Vector-Based Animations</h1>
            <FeatureBoxWrapper>
                <FeatureBox />
            </FeatureBoxWrapper>
            </Feature>
            <Introduction>
            <ProjectOverViewWrapper>
                <ProjectOverview>
                <h2>Project Overview</h2>
                <h4>While at merQbiz®, I was fortunate enough to work on some visual marketing/ communication projects with our in-house experts. The following animations leveraged lighter-weight vector shapes using <a href="https://airbnb.design/lottie/" target="_blank" rel="noreferrer">Lottie.js</a> libraries, and brought a whole new world of animation controls to the team. Collaboration with dev was improved as handoff was a small JSON file that rendered an exact replica of approved animations.</h4>
                </ProjectOverview>
            </ProjectOverViewWrapper>
            <ProjectDetailsWrapper>
                <ProjectDetails className="projectDetails">
                <hr></hr>
                <h5 className="details">Credits</h5>
                <p className="details">Storyboarding, concepting and animation production.
                Creative Direction from <a href="https://www.linkedin.com/in/chrisbrandsey/" target="_blank" rel="noreferrer">Chris Brandsey</a>, Art Direction from <a href="https://www.linkedin.com/in/rahulkaushalmba/" target="_blank" rel="noreferrer">Rahul Kaushal</a> and <a href="https://www.linkedin.com/in/hafsahsiddiq/" target="_blank" rel="noreferrer">Hafsah Siddiq</a>, Inspiration from <a href="https://www.linkedin.com/in/travis-radtke-6b89042a/" target="_blank" rel="noreferrer">Travis Radke</a> with <a href="https://www.linkedin.com/in/niklas-wenzler-b24a22148/" target="_blank" rel="noreferrer">Nicklas Wenzler</a> and <a href="https://www.linkedin.com/in/reikurata/" target="_blank" rel="noreferrer">Rei Kurata</a> Front End team.</p>
                </ProjectDetails>
                <ProjectDetails className="projectDetails">
                <h5 className="details">Client</h5>
                <p className="details">merQbiz®</p>
                </ProjectDetails>
                {/* <ProjectDetails className="projectDetails">
                <h5 className="details">agency</h5>
                <p className="details">Independent project</p>
                </ProjectDetails> */}
                <ProjectDetails className="projectDetails">
                <h5 className="details">Deliverable</h5>
                <p className="details">Series of largely vector-based microanimations for the marketing website.</p>
                </ProjectDetails>
                <ProjectDetails className="projectDetails">
                <h5 className="details">Goal</h5>
                <p className="details">Add engagement and strengthen storytelling in the waste industry, without marketing actual waste.</p>
                </ProjectDetails>
                <ProjectDetails className="projectDetails">
                <h5 className="details">RESULTS</h5>
                <p className="details">Accolades from management and sales on the improvement of quality and timeline of our animations.</p>
                </ProjectDetails>
                <hr></hr>
            </ProjectDetailsWrapper>
            </Introduction>          
            <ProjectDescription className="projectChapter">
            <h2>Examples</h2>
            <AnimationWrapper>
                <AnimationHut>
                <ReducedLandfill />
                <div className="imgcaption">Token animation representing reduced landfill</div>
                </AnimationHut>
                <AnimationHut>
                <EfficientOperations />
                <div className="imgcaption">Token animation representing improved supplier mix</div>
                </AnimationHut>
                <AnimationHut>
                <UnlockBale />
                <div className="imgcaption">Abstract animation showing contaminant breakdown in a bale</div>
                </AnimationHut>
                <AnimationHut>
                <GatherData />
                <div className="imgcaption">Logic animation showing incoming data points for predictive procurement models</div>
                </AnimationHut>
                <AnimationHut>
                <ValueProps />
                <div className="imgcaption">High-level value propositions</div>
                </AnimationHut>
                <AnimationHut>
                <DataAnalysis />
                <div className="imgcaption">Abstract animation showing data analysis</div>
                </AnimationHut>
            </AnimationWrapper>
            {/* <p>Using a mix of Sketch and Principle, the following feature demonstration was created to show the value of the “Schedule Basket.” In presentation, a live demo was created, below is a screen-grab video that encapsulates the idea. The feature is demonstrated when the user story flips to wireframes.</p> 
            <VideoEmbed className="projectDemo">
                <iframe src="https://www.youtube.com/embed/nAXPm9P0mwU?rel=0" frameborder="0" allowfullscreen></iframe>
            </VideoEmbed>
            <h3>Conclusions</h3>
            <p>My goal for this project was to incorporate learnings from Product School into a feature from research through computational design that could be presented with clarity. A go to market plan was created, and while the feature was not built and tested, the demo was well received.</p>
            <p>Thanks to Siddharth Nirmal for letting me know about Thrive Market. On a side not, I leave the class a paying member where I shop for organic staple savings while giving to a family in need. Their organic crunchy peanut butter is amahzing and rightfully, currently on back-order.</p>
            <p>If you are curious about trying out Thrive Market, then be sure to refer me as a friend here to save 15% on membership. If you are from Thrive Market, and are reading this, please, let’s grab a cup of coffee. Drop me a line.</p>*/}
            </ProjectDescription>
            <ScrollOnUp 
            onClick={() => scrollTo('#top')}>
            <div className="ScrollToTop">
                <p>Scroll to top</p>
                <i className="fas fa-angle-up"></i>
            </div>
            </ScrollOnUp>
        </ProjectWrapper>
        </div>
    </Layout>
  )

export default ltt