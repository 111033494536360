import * as React from "react"
// import * as LottiePlayer from "@lottiefiles/lottie-player"

class EfficientOperations extends React.Component {
  constructor(props) {
    super(props);
    this.state = { lottie: null }; // initialize your state
  }

  render() {
    return (
      <lottie-player
        lottieRef={instance => {
          this.setState({ lottie: instance }); // the lottie instance is returned in the argument of this prop. set it to your local state
        }}
        autoplay={false}
        loop={true}
        controls={true}
        src="https://assets4.lottiefiles.com/packages/lf20_y9u2rcpb.json"
        style={{ width: '100%' }}>
      </lottie-player>
    );
  }
}

export default EfficientOperations;